import { InProcessFilter, Process, Profession } from '@/lib/types';

export type ProfFilter = Profession | 'All';

export enum InProcessStep {
    PhoneInterview = 'phoneInterview',
    InPersonInterview = 'inPersonInterview',
    Paperwork = 'paperwork',
    Orientation = 'orientation',
    InProgress = 'inProgress',
}

export type TopRowBaseProps = {
    initiatedRecruiterAv: boolean;
    openSetAvailabilityDialog: () => void;
    openSetAvailabilityDialogDismissOthers: () => void;
};

export type ProfessionFilterProps = {
    models: Process[];
    profFilter: ProfFilter;
    setProfFilter: (prof: ProfFilter) => void;
};

export type InProcessFilterProps = {
    models: Process[];
    filter: InProcessFilter;
    setFilter: (prof: InProcessFilter) => void;
};

export type NewCandidatesTopRowProps = TopRowBaseProps & ProfessionFilterProps;
export type InProcessTopRowProps = TopRowBaseProps & InProcessFilterProps;

export type NewCandidatesViewProps = {
    processes: Process[];
    profFilter: ProfFilter;
    setProfFilter: (prof: ProfFilter) => void;
    initiatedRecruiterAv: boolean;
    openSetAvailabilityDialog: () => void;
    openSetAvailabilityDialogDismissOthers: () => void;
    filteredProcesses: Process[];
    totalNewCandidates: number;
    isLoadingProcesses: boolean;
    initiatedProcesses: boolean;
    noNewCandidates: boolean;
};

export type InProcessCandidatesViewProps = {
    processes: Process[];
    filteredProcesses: Process[];
    filter: InProcessFilter;
    onFilterChange: (prof: InProcessFilter) => void;
    initiatedRecruiterAv: boolean;
    openSetAvailabilityDialog: () => void;
    openSetAvailabilityDialogDismissOthers: () => void;
    onProcessClick: (processId: string) => void;
    isEmpty: boolean;
    isLoading: boolean;
    initiated: boolean;
};
