import Loader from '@/components/lotties/Loader';
import { ScrollableFillerDiv } from '@/components/ui';
import { cn } from '@/lib/utils';
import { candidates as strings } from '@/strings';

import NewCandidatesMobile from './NewCandidatesMobile';
import NewCandidatesTopRow from './NewCandidatesTopRow';
import NoCandidatesView from './NoCandidatesView';
import { NewCandidatesViewProps } from './types';

const tableStrings = strings.newRequests.candidatesTable;

function NewRequestsMobileLayout({
    processes,
    profFilter,
    setProfFilter,
    initiatedRecruiterAv,
    openSetAvailabilityDialog,
    openSetAvailabilityDialogDismissOthers,
    filteredProcesses,
    isLoadingProcesses,
    initiatedProcesses,
    noNewCandidates,
}: NewCandidatesViewProps) {
    return (
        <ScrollableFillerDiv
            className={cn('hidden-scrollbar')}
            growDivProps={{ className: cn('relative px-6') }}
            fixedDiv={
                <NewCandidatesTopRow
                    profFilter={profFilter}
                    setProfFilter={setProfFilter}
                    models={processes}
                    initiatedRecruiterAv={initiatedRecruiterAv}
                    openSetAvailabilityDialog={openSetAvailabilityDialog}
                    openSetAvailabilityDialogDismissOthers={
                        openSetAvailabilityDialogDismissOthers
                    }
                />
            }
        >
            <div className={cn('py-6')}>
                <NewCandidatesMobile models={filteredProcesses} />
                {isLoadingProcesses && !initiatedProcesses && (
                    <div
                        className={cn(
                            'absolute pt-20 flex items-center justify-center w-full h-full top-0 left-0',
                        )}
                    >
                        <Loader variant='primary' sizing='lg' />
                    </div>
                )}
                {noNewCandidates && <NoCandidatesView text={tableStrings.emptyTable} />}
            </div>
        </ScrollableFillerDiv>
    );
}

export default NewRequestsMobileLayout;
