import { cn } from '@/lib/utils';
import { composeEventHandlers } from '@radix-ui/primitive';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';

import { DialogContent, DialogOverlay, DialogPortal } from './base';
import DialogContentView from './dialogContent';
import { DialogViewProps } from './types';
import {
    useDragToCloseDialogDispatcher,
    useDragToCloseDialogListener,
} from './useDraggableDialog';

function BottomBackgroundFiller({
    open,
    draggedDownPosition,
}: {
    open: boolean;
    draggedDownPosition: number;
}) {
    const [shown, setShown] = useState(false);
    useEffect(() => {
        setShown(open);
    }, [open]);
    return (
        <div
            className={cn(
                'absolute w-full bg-white bottom-0 h-0 -mb-10',
                'border-x border-slate-200',
                'transition-all duration-300 ease-in-out',
                { hidden: !open },
                { 'h-20 mb-0': shown && open && draggedDownPosition <= 0 },
                { 'h-40 mb-0': shown && open && draggedDownPosition < -55 },
            )}
        />
    );
}

function DialogViewMobile({
    portalProps,
    overlayProps,
    contentProps,
    contentWrapperProps,
    handleClose,
    children,
    open,
    ...contentViewProps
}: DialogViewProps) {
    const { draggedDownPosition, dialogContentRef, isExpandedToTop } =
        useDragToCloseDialogListener();
    const { bindDragProps } = useDragToCloseDialogDispatcher();
    const dragProps = bindDragProps();
    return (
        <DialogPortal {...portalProps}>
            <DialogOverlay
                {...overlayProps}
                onPointerDown={composeEventHandlers(overlayProps?.onPointerDown, (e) =>
                    e.stopPropagation(),
                )}
                handleClose={handleClose}
                className={cn(
                    'data-[state=open]:animate-overlay-opacity-show-fast data-[state=closed]:animate-overlay-opacity-hide-fast',
                    overlayProps?.className,
                )}
            >
                <DialogContent
                    {...contentProps}
                    className={cn(
                        'group',
                        'focus-visible:outline-none',
                        'overflow-hidden',
                        'bottom-0 max-w-full w-full h-full rounded-none translate-y-0 hidden-scrollbar bg-transparent border-none',
                        'data-[state=open]:animate-wait-in data-[state=closed]:animate-wait-out',
                        'max-h-full',
                        contentProps?.className,
                    )}
                    style={{ top: 'unset' }}
                >
                    <BottomBackgroundFiller
                        open={open}
                        draggedDownPosition={draggedDownPosition}
                    />
                    <motion.div
                        initial={{
                            translateY: open ? '100%' : 0,
                        }}
                        animate={{
                            translateY: open ? '0%' : '100%',
                        }}
                        transition={{
                            type: 'spring',
                            stiffness: 400,
                            damping: 30,
                            mass: 1,
                        }}
                        className={cn(
                            'relaitve flex flex-col justify-end w-full h-full overflow-hidden mt-auto bottom-0',
                            {
                                'transition-[height] duration-200 ease-in-out':
                                    draggedDownPosition >= 0,
                            },
                        )}
                        style={{
                            height: isExpandedToTop
                                ? '100%'
                                : `calc(90% - ${Math.min(0, draggedDownPosition)}px)`,
                        }}
                    >
                        <div
                            {...contentWrapperProps}
                            className={cn(
                                'relative flex flex-col items-stretch h-fit max-h-full mt-auto bottom-0 rounded-t-2xl border border-b-0 border-slate-200 bg-white shadow-lg-no-bottom overflow-hidden',
                                'touch-none',
                                contentWrapperProps?.className,
                            )}
                            {...dragProps}
                            onClick={(e) => e.stopPropagation()}
                            style={{
                                transform: `translateY(${Math.max(0, draggedDownPosition)}px)`,
                            }}
                            ref={dialogContentRef}
                        >
                            <DialogContentView
                                {...contentViewProps}
                                headerProps={{
                                    ...contentViewProps?.headerProps,
                                    className: cn(
                                        'border-b border-neutral100 relative',
                                        contentViewProps?.headerProps?.className,
                                    ),
                                }}
                                handleClose={handleClose}
                            >
                                {children}
                            </DialogContentView>
                        </div>
                    </motion.div>
                </DialogContent>
            </DialogOverlay>
        </DialogPortal>
    );
}

export default DialogViewMobile;
