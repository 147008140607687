import { DialogContentWithFooter } from '@/components/ui';
import { useIsMobileScreen } from '@/hooks/useMediaQueries';
import { Process } from '@/lib/types';
import { cn } from '@/lib/utils';

import CandidateActions from './CandidateActions';
import ProcessDetails from './ProcessDetails';
import ProcessDetailsMobile from './ProcessDetailsMobile';

interface Props {
    process: Process;
    handleClose: () => void;
}

export function CandidateDetailsDialogContent({ process }: Props) {
    const isMobileScreen = useIsMobileScreen();
    return (
        <DialogContentWithFooter
            footer={
                <div className={cn('flex justify-end xs:justify-center')}>
                    <CandidateActions process={process} />
                </div>
            }
            bodyProps={{ className: cn('xs:p-0 bg-accent500') }}
        >
            {isMobileScreen && <ProcessDetailsMobile process={process} />}
            {!isMobileScreen && (
                <div className={cn(' w-full p-4')}>
                    <ProcessDetails process={process} />
                </div>
            )}
        </DialogContentWithFooter>
    );
}

export default CandidateDetailsDialogContent;
