import { ProfessionAvatar } from '@/components/ui';
import { Candidate, Profession } from '@/lib/types';
import { cn } from '@/lib/utils';
import { candidates as candidatesStrings } from '@/strings';

export function NameAndRoleCell({ candidate }: { candidate: Candidate }) {
    const { profession, name } = candidate;
    return (
        <div className={cn('flex items-center w-full')}>
            <ProfessionAvatar
                profession={profession as Profession}
                className={cn('w-[54px] h-[54px] lg:w-14 lg:h-14')}
            />
            <div className={cn('pl-4 w-[calc(100%-54px)]')}>
                <p
                    className={cn(
                        'text-15 font-semibold max-w-full overflow-hidden whitespace-nowrap text-ellipsis',
                    )}
                >
                    {name}
                </p>
                <p className={cn('text-12')}>
                    {
                        candidatesStrings.newRequests.professionToName[
                            profession as Profession
                        ]
                    }
                </p>
            </div>
        </div>
    );
}

export default NameAndRoleCell;
