import { dayOfWeekOptions } from '@/lib/api/v1';
import { cn } from '@/lib/utils';

import DayAvailability from './DayAvailability';

function AvailabilityForm() {
    return (
        <div className={cn('relative w-full h-full')}>
            <div className={cn('absolute w-full h-full')}>
                <div className={cn('relative w-full h-full overflow-auto')}>
                    {dayOfWeekOptions.map((day) => (
                        <DayAvailability key={day} day={day} />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default AvailabilityForm;
