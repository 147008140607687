import { Dialog } from '@/components/ui';
import { useIsMobileScreen } from '@/hooks/useMediaQueries';
import { Process } from '@/lib/types';
import { cn } from '@/lib/utils';

import { BaseDialogPropsCommon } from '../types';
import CandidateHeader from './CandidateHeader';
import CandidateHeaderMobile from './CandidateHeaderMobile';
import { CandidateDetailsDialogContent } from './content';

interface Props extends BaseDialogPropsCommon {
    process: Process;
}

function CandidateDetailsDialog({ process, isOpen, setOpen, handleClose }: Props) {
    const isMobileScreen = useIsMobileScreen();
    return (
        <Dialog
            open={isOpen}
            setOpen={setOpen}
            contentProps={{ className: cn('max-w-[90%] xs:max-w-full') }}
            contentWrapperProps={{
                className: cn('max-w-[90vw] w-[1300px] xs:max-w-full'),
            }}
            headerProps={{
                className: cn('py-8 pr-8'),
            }}
            title={
                isMobileScreen ? (
                    <CandidateHeaderMobile process={process} />
                ) : (
                    <CandidateHeader process={process} />
                )
            }
        >
            <CandidateDetailsDialogContent process={process} handleClose={handleClose} />
        </Dialog>
    );
}

export default CandidateDetailsDialog;
