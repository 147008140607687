import { DialogContentWithFooter, useDialogContext } from '@/components/ui';
import { useIsMobileScreen } from '@/hooks/useMediaQueries';
import { cn, isTouchDevice } from '@/lib/utils';
import { range } from 'lodash';
import { Page } from 'react-pdf';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import { PDF_PAGE_FIT_TO_CONTAINER_CLS } from './consts';

function PDFPages({ numPages, className }: { numPages: number; className?: string }) {
    const isMobileScreen = useIsMobileScreen();
    return (
        <>
            {range(numPages).map((_e, index) => {
                const number = Number(index) + 1;
                return (
                    <div
                        key={`page_${number}`}
                        className={cn('h-full w-full', className)}
                    >
                        <Page
                            pageNumber={number}
                            width={924}
                            className={cn({
                                [PDF_PAGE_FIT_TO_CONTAINER_CLS]: isMobileScreen,
                            })}
                        />
                    </div>
                );
            })}
        </>
    );
}

function ZoomablePdfContent({ numPages }: { numPages: number }) {
    return (
        <div
            className={cn('relative h-full w-full mx-auto px-4 py-2 overflow-auto')}
            onPointerDown={(e) => e.stopPropagation()}
        >
            <TransformWrapper>
                <TransformComponent
                    wrapperClass={cn('max-h-full')}
                    contentClass={cn('max-h-full')}
                >
                    <PDFPages numPages={numPages} className='h-fit' />
                </TransformComponent>
            </TransformWrapper>
        </div>
    );
}

function PDFDialogContent({ numPages }: { numPages: number }) {
    const { isExpandedToTop } = useDialogContext();
    const isMobileScreen = useIsMobileScreen();
    const shouldShowZommableView = isTouchDevice() && isMobileScreen && isExpandedToTop;
    const { scrollRef } = useDialogContext();
    return (
        <DialogContentWithFooter bodyProps={{ className: cn('flex-grow py-0 xs:px-0') }}>
            <div className={cn('relative h-full w-full')}>
                <div
                    className={cn(
                        'absolute h-full w-full overflow-auto p-8 pt-0 xs:p-0',
                    )}
                    ref={shouldShowZommableView ? undefined : scrollRef}
                >
                    <div className={cn('relative h-full w-fit mx-auto')}>
                        {shouldShowZommableView && (
                            <ZoomablePdfContent numPages={numPages} />
                        )}
                        {!shouldShowZommableView && (
                            <PDFPages
                                numPages={numPages}
                                className={cn('h-auto w-auto xs:px-4')}
                            />
                        )}
                    </div>
                </div>
            </div>
        </DialogContentWithFooter>
    );
}

export default PDFDialogContent;
