import { useAppDispatch } from '@/_helpers/store';
import isMobileSlice from '@/_reducers/isMobile';
import { useMediaQuery } from '@uidotdev/usehooks';
import { useEffect } from 'react';

export enum ScreenSize {
    Mobile = 'Mobile',
    Medium = 'Medium',
    Desktop = 'Desktop',
}

function getCurrentScreenSize({
    isMediumScreen,
    isMobile,
}: {
    isMobile: boolean;
    isMediumScreen: boolean;
}): ScreenSize {
    if (isMobile) {
        return ScreenSize.Mobile;
    }
    if (isMediumScreen) {
        return ScreenSize.Medium;
    }
    return ScreenSize.Desktop;
}

function useMediaQueries() {
    const dispatch = useAppDispatch();
    const isMobile = useMediaQuery('only screen and (max-width: 800px)');
    const isMediumScreen = useMediaQuery('only screen and (max-width: 1360px)');
    const screenSize = getCurrentScreenSize({ isMobile, isMediumScreen });
    useEffect(() => {
        dispatch(isMobileSlice.actions.set(isMobile));
    }, [isMobile, dispatch]);
    return screenSize;
}

export function useIsMobileScreen() {
    const screenSize = useMediaQueries();
    return screenSize === ScreenSize.Mobile;
}

export function useIsMediumScreen() {
    const screenSize = useMediaQueries();
    return screenSize === ScreenSize.Mobile;
}
