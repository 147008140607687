import { Tabs } from '@/components/ui';
import useLocationHash from '@/hooks/useLocationHash';
import useSessionSetting from '@/hooks/useSessionSetting';
import useSetHash from '@/hooks/useSetHash';
import { getAllEnumValues } from '@/lib/base';
import { CandidatesTab } from '@/lib/types/queryParams';
import { cn } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { candidates as strings } from '@/strings';
import { useEffect } from 'react';

import NewRequests from './NewRequests';
import InProcess from './inProcess/InProcess';

const tabOptions = getAllEnumValues(CandidatesTab);

const useTabFromHash = () => {
    const setHash = useSetHash();
    const selectedTab = useLocationHash() as CandidatesTab;
    const { val: defaultTab, setVal: setDefaultTab } =
        useSessionSetting('candidatesTab');

    const setSelectedTab = (tab: CandidatesTab) => {
        setHash(tab);
    };

    useEffect(() => {
        if (!tabOptions.includes(selectedTab)) {
            setSelectedTab(defaultTab ?? CandidatesTab.New);
        } else {
            setDefaultTab(selectedTab);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTab]);

    return [selectedTab, setSelectedTab] as [
        CandidatesTab,
        (tab: CandidatesTab) => void,
    ];
};

function Candidates() {
    const [tab, setTab] = useTabFromHash();

    useEffect(() => {
        if (tab === CandidatesTab.New) {
            trackEvent(MixpanelEvent.ViewSectionNewRequests);
        } else if (tab === CandidatesTab.InProcess) {
            trackEvent(MixpanelEvent.ViewSectionInProcess);
        }
    }, [tab]);

    return (
        <Tabs
            value={tab}
            setValue={(v: string) => setTab(v as CandidatesTab)}
            className={cn('p-0 h-full')}
            tabsListContainerProps={{
                className: cn('bg-white p-8 lg:py-4 xs:p-5 flex justify-center'),
            }}
            tabs={[
                {
                    id: CandidatesTab.New,
                    header: strings.newRequests.tabLabel,
                    content: <NewRequests />,
                },
                {
                    id: CandidatesTab.InProcess,
                    header: strings.inProcess.tabLabel,
                    content: <InProcess />,
                },
            ]}
        />
    );
}

export default Candidates;
