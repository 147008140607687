import { useIsMobileScreen } from '@/hooks/useMediaQueries';
import useSetOpen from '@/hooks/useSetOpen';
import { cn } from '@/lib/utils';
import { useRef, useState } from 'react';

import {
    DialogBase,
    DialogContent,
    DialogOverlay,
    DialogPortal,
    DialogTrigger,
} from './base';
import DialogContext from './context';
import DialogContentView from './dialogContent';
import DialogViewMobile from './dialogMobile';
import { CustomDialogProps, DialogAnimationType, DialogViewProps } from './types';

function DialogViewDesktop({
    portalProps,
    overlayProps,
    contentProps,
    animation,
    contentWrapperProps,
    CustomWrapper,
    handleClose,
    ...contentViewProps
}: DialogViewProps) {
    return (
        <DialogPortal {...portalProps}>
            <DialogOverlay
                {...overlayProps}
                className={cn(
                    {
                        'data-[state=open]:animate-overlay-opacity-show data-[state=closed]:animate-overlay-opacity-hide':
                            animation === DialogAnimationType.SLIDE,
                    },
                    {
                        'data-[state=open]:animate-opacity-in data-[state=closed]:animate-opacity-out':
                            animation === DialogAnimationType.FADE,
                    },
                    overlayProps?.className,
                )}
            >
                <DialogContent
                    {...contentProps}
                    className={cn(
                        'group',
                        'focus-visible:outline-none',
                        {
                            'data-[state=closed]:animate-dialog-close-slide  data-[state=open]:animate-dialog-open-slide':
                                animation === DialogAnimationType.SLIDE,
                        },
                        {
                            'data-[state=open]:animate-opacity-in data-[state=closed]:animate-opacity-out':
                                animation === DialogAnimationType.FADE,
                        },
                        contentProps?.className,
                    )}
                >
                    <div
                        {...contentWrapperProps}
                        className={cn(
                            'relative',
                            {
                                'group-data-[state=closed]:animate-dialog-close-slide-content  group-data-[state=open]:animate-dialog-open-slide-content':
                                    animation === DialogAnimationType.SLIDE,
                            },
                            contentWrapperProps?.className,
                        )}
                    >
                        {CustomWrapper && (
                            <CustomWrapper>
                                <DialogContentView
                                    {...contentViewProps}
                                    handleClose={handleClose}
                                />
                            </CustomWrapper>
                        )}
                        {!CustomWrapper && (
                            <DialogContentView
                                {...contentViewProps}
                                handleClose={handleClose}
                            />
                        )}
                    </div>
                </DialogContent>
            </DialogOverlay>
        </DialogPortal>
    );
}

function Dialog({
    open: externalOpen,
    setOpen: setExternalOpen = () => {},
    trigger,
    animation = DialogAnimationType.SLIDE,
    triggerProps,
    overlayProps,
    portalProps,
    contentProps,
    dialogProps,
    contentWrapperProps,
    CustomWrapper,
    ...contentViewProps
}: CustomDialogProps) {
    const isMobileScreen = useIsMobileScreen();

    const { handleClose, handleOpen, handleOpenChange, open } = useSetOpen(
        externalOpen,
        setExternalOpen,
    );
    const dialogContentRef = useRef<HTMLDivElement>(null);
    const scrollRef = useRef<HTMLDivElement>(null);

    const [draggedDownPosition, setDraggedDownPosition] = useState(0);
    const [isExpandedToTop, setIsExpandedToTop] = useState(false);

    return (
        <DialogContext.Provider
            value={{
                handleClose,
                handleOpen,
                isExpandedToTop,
                setIsExpandedToTop,
                draggedDownPosition,
                setDraggedDownPosition,
                open,
                dialogContentRef,
                scrollRef,
            }}
        >
            <DialogBase open={open} onOpenChange={handleOpenChange} {...dialogProps}>
                {trigger && <DialogTrigger {...triggerProps}>{trigger}</DialogTrigger>}
                {isMobileScreen ? (
                    <DialogViewMobile
                        {...{
                            portalProps,
                            overlayProps,
                            contentProps,
                            animation,
                            contentWrapperProps,
                            CustomWrapper,
                            ...contentViewProps,
                            handleClose,
                            open,
                        }}
                    />
                ) : (
                    <DialogViewDesktop
                        {...{
                            portalProps,
                            overlayProps,
                            contentProps,
                            animation,
                            contentWrapperProps,
                            CustomWrapper,
                            ...contentViewProps,
                            handleClose,
                            open,
                        }}
                    />
                )}
            </DialogBase>
        </DialogContext.Provider>
    );
}

function DialogFadeAnimation({ ...props }: Omit<CustomDialogProps, 'animate'>) {
    return (
        <Dialog
            animation={DialogAnimationType.FADE}
            {...{
                ...props,
            }}
        />
    );
}

export { DialogFadeAnimation };
export default Dialog;
