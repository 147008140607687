import CandidateAdditionalAttributes from '@/candidateDetails/CandidateAdditionalAttributes';
import CandidateJobMatchesProvider from '@/candidateDetails/CandidateJobMatchesProvider';
import CandidateResumeTile from '@/candidateDetails/CandidateResumeTile';
import DetailsCard from '@/candidateDetails/DetailsCard';
import { Process } from '@/lib/types';
import { cn, isNewProcess } from '@/lib/utils';
import ProcessStatusBadge from '@/pages/candidates/inProcess/ProcessStatusBadge';
import ProcessHistoryFlow from '@/processHistory/ProcessHistoryFlow';
import strings from '@/strings/dialogs/candidateDetails';

import CandidateMainDetailsMobile from './CandidateMainDetailsMobile';
import CandidateContactInfo from './ContactInfo';

function ProcessDetailsMobile({ process }: { process: Process }) {
    const isNewCandidate = isNewProcess(process);

    return (
        <div className={cn('relative w-full h-full')}>
            {!isNewCandidate && (
                <div
                    className={cn(
                        'flex justify-center w-full bg-white border-t border-neutral100',
                        'p-8',
                    )}
                >
                    <CandidateContactInfo process={process} />
                </div>
            )}
            <div className={cn('p-4 flex items-stretch flex-col gap-4')}>
                <CandidateMainDetailsMobile process={process} />
                <CandidateResumeTile
                    process={process}
                    cardProps={{ className: cn('h-[360px] w-full') }}
                />
                <CandidateJobMatchesProvider
                    process={process}
                    cardProps={{ className: cn('w-full') }}
                />
                <CandidateAdditionalAttributes process={process} />
                {!isNewCandidate && (
                    <DetailsCard
                        title={
                            <div
                                className={cn(
                                    'w-full flex justify-between items-center px-8',
                                )}
                            >
                                <div className={cn('text-14 font-bold')}>
                                    {strings.processStatusLabel}
                                </div>
                                <ProcessStatusBadge process={process} />
                            </div>
                        }
                    >
                        <ProcessHistoryFlow process={process} />
                    </DetailsCard>
                )}
            </div>
        </div>
    );
}

export default ProcessDetailsMobile;
