import { useIsMobileScreen } from '@/hooks/useMediaQueries';

import HomePageLayout from './HomePageLayout';
import HomePageMobileLayout from './mobile/HomePageMobileLayout';
import usePendingTasks from './usePendingTasks';

function HomePage() {
    const isMobileScreen = useIsMobileScreen();
    const { isLoadingTasks, initiatedTasks, processes } = usePendingTasks();

    return isMobileScreen ? (
        <HomePageMobileLayout
            {...{
                isLoadingTasks,
                initiatedTasks,
                pendingTasksProcesses: processes,
            }}
        />
    ) : (
        <HomePageLayout
            {...{
                isLoadingTasks,
                initiatedTasks,
                pendingTasksProcesses: processes,
            }}
        />
    );
}

export default HomePage;
