const mainTheme = {
    container: {
        center: true,
        padding: '2rem',
    },
    extend: {
        screens: {
            xs: { min: '0px', max: '800px' },
            lg: { min: '800px', max: '1360px' },
            xl: { min: '1360px', max: '1580px' },
        },
        borderRadius: {
            '4xl': 20,
        },
        colors: {
            accent800: '#08687D',
            accent700: '#096E84',
            primary: '#09748B',
            accent600: '#09748B',
            accent500: '#E6F1F3',
            accent400: '#EBF4F6',
            accent300: '#F3F8F9',
            accent200: '#F5F9FA',
            accent100: '#F8FBFC',
            neutral300: '#A0A0A0',
            neutral200: '#E9E9E9',
            neutral100: '#F4F4F4',
            red200: '#EE442D',
            red100: '#F05E4A',
            red50: '#FFE4E4',
            blue: '#1969E0',
            green: '#00FF29',
            gray50: '#F2F7FF',
            blueDark: '#243C61',
            brown600: '#733700',
            yellow200: '#FFF9EC',
            phoneInterview: '#F5F5FF',
            inPersonInterview: '#FFF7DA',
            paperwork: '#EBFFEB',
            orientation: '#FFFDCF',
        },
        fontFamily: {
            sans: ['Inter', 'sans-serif'],
            serif: ['Inter', 'serif'],
            mono: ['Inter', 'monospace'],
        },
        fontSize: {
            base: '12px',
            '11': ['11px', { lineHeight: '18px' }],
            '12': ['12px', { lineHeight: '20px' }],
            '13': ['13px', { lineHeight: '22px' }],
            '13-23': ['13px', { lineHeight: '23px' }],
            '14': ['14px', { lineHeight: '24px' }],
            '15': ['15px', { lineHeight: '25px' }],
            '16': ['16px', { lineHeight: '26px' }],
            '17': ['17px', { lineHeight: '17px' }],
            '18': ['18px', { lineHeight: '18px' }],
            '20': ['20px', { lineHeight: '20px' }],
            '24': ['24px', { lineHeight: '24px' }],
        },
        padding: {
            '1px': '1px',
        },
        boxShadow: {
            'elevation-100': '0px 5px 40px 0px rgba(0, 0, 0, 0.05)',
            'elevation-200': '0px 5px 40px 0px rgba(0, 0, 0, 0.1)',
            'elevation-300': '0px 10px 60px 0px rgba(0, 0, 0, 0.1)',
            'lg-no-bottom':
                '0 -10px 15px -3px rgba(0, 0, 0, 0.1), 0 -4px 6px -4px rgba(0, 0, 0, 0.1)',
        },
        keyframes: {
            'opacity-show': {
                '0%': { opacity: 0 },
                '100%': { opacity: 1 },
            },
            'opacity-hide': {
                '0%': { opacity: 1 },
                '100%': { opacity: 0 },
            },
            'overlay-opacity-show': {
                '0%': { backgroundColor: 'rgba(0, 0, 0, 0)' },
                '100%': { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
            },
            'overlay-opacity-hide': {
                '0%': { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
                '50%': { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
                '100%': { backgroundColor: 'rgba(0, 0, 0, 0)' },
            },
            'dialog-open-slide': {
                '0%': { 'max-height': '0%', overflowY: 'hidden' },
                '50%': { 'max-height': '95%' },
            },
            'dialog-open-slide-content': {
                '0%': { opacity: 0 },
                '50%': { opacity: 0 },
                '100%': { opacity: 1 },
            },
            'dialog-close-slide-content': {
                '0%': { opacity: 1 },
                '50%': { opacity: 0 },
                '100%': { opacity: 0 },
            },
            'dialog-close-slide': {
                '0%': { 'max-height': '95%' },
                '50%': { 'max-height': '95%' },
                '100%': { 'max-height': '0%', overflowY: 'hidden' },
            },
            'wait-in': {
                '0%': { opacity: '1' },
                '100%': { opacity: '1' },
            },
            'wait-out': {
                '0%': { opacity: '1' },
                '100%': { opacity: '1' },
            },
        },
        animation: {
            'dialog-close-slide': 'dialog-close-slide 0.6s ease-in-out',
            'dialog-open-slide': 'dialog-open-slide 0.6s ease-in-out',
            'dialog-open-slide-content': 'dialog-open-slide-content 0.6s ease-in-out',
            'dialog-close-slide-content': 'dialog-close-slide-content 0.6s ease-in-out',
            'overlay-opacity-show': 'overlay-opacity-show 0.6s ease-in-out',
            'overlay-opacity-hide': 'overlay-opacity-hide 0.6s ease-in-out',
            'overlay-opacity-hide-fast': 'overlay-opacity-hide 0.3s ease-in-out',
            'overlay-opacity-show-fast': 'overlay-opacity-show 0.3s ease-in-out',
            'opacity-in': 'opacity-show 0.3s ease-in-out',
            'opacity-out': 'opacity-hide 0.3s ease-in-out',
            'wait-in': 'wait-in 0.6s ease-in-out',
            'wait-out': 'wait-out 0.6s ease-in-out',
        },
    },
};

export default mainTheme;
