import CandidateAdditionalAttributesSmall from '@/candidateDetails/CandidateAdditionalAttributesSmall';
import CandidateJobMatchesProvider from '@/candidateDetails/CandidateJobMatchesProvider';
import CandidateMainDetails from '@/candidateDetails/CandidateMainDetails';
import CandidateResumeTile from '@/candidateDetails/CandidateResumeTile';
import { ScrollOnHoverVertical, ScrollableFillerDiv } from '@/components/ui';
import { Process } from '@/lib/types';
import { cn } from '@/lib/utils';
import ProcessStatusBadge from '@/pages/candidates/inProcess/ProcessStatusBadge';
import ProcessHistoryFlow from '@/processHistory/ProcessHistoryFlow';
import strings from '@/strings/dialogs/candidateDetails';

function ProcessDetails({ process }: { process: Process }) {
    return (
        <div className={cn('relative flex flex-nowrap gap-4')}>
            <div className={cn('flex-grow')}>
                <CandidateMainDetails process={process} />
                <div
                    className={cn('relative flex flex-nowrap gap-4 mt-4 items-stretch')}
                >
                    <div
                        className={cn(
                            'basis-[450px] min-w-[350px] lg:basis-[400px] flex-shrink flex-grow',
                        )}
                    >
                        <div className={cn('flex h-full flex-col')}>
                            <div className={cn('flex-grow')}></div>
                            <CandidateJobMatchesProvider
                                process={process}
                                cardProps={{ className: cn('h-full') }}
                            />
                            <div className={cn('pt-4')}>
                                <CandidateAdditionalAttributesSmall process={process} />
                            </div>
                        </div>
                    </div>
                    <div className={cn('w-[400px] lg:w-[300px] flex-shrink-0')}>
                        <CandidateResumeTile
                            process={process}
                            fixedThumbnalWidth
                            pdfContainerProps={{
                                className: cn('h-[360px] lg:h-[280px]'),
                            }}
                            noBottomRow
                            cardProps={{ className: cn('h-full') }}
                        />
                    </div>
                </div>
            </div>
            <div className={cn('basis-[400px] min-w-[300px] rounded-xl bg-white')}>
                <ScrollableFillerDiv
                    fixedDiv={
                        <div
                            className={cn(
                                'flex justify-between items-center p-6 border-b border-neutral100',
                            )}
                        >
                            <div className={cn('text-14 font-bold')}>
                                {strings.processStatusLabel}
                            </div>
                            <ProcessStatusBadge process={process} />
                        </div>
                    }
                >
                    <ScrollOnHoverVertical>
                        <ProcessHistoryFlow process={process} />
                    </ScrollOnHoverVertical>
                </ScrollableFillerDiv>
            </div>
        </div>
    );
}

export default ProcessDetails;
